<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号" prop="id">
                <a-input v-model="queryParam.id" placeholder="请输入订单编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入商铺名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="三方支付订单号" prop="outTradeNo">
                <a-input v-model="queryParam.outTradeNo" placeholder="请输入三方支付订单号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="支付类型" prop="payType">
                <a-select placeholder="请选择支付类型" style="width: 100%" v-model="queryParam.payType" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderPayTypeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否中标" prop="payType">
                <a-select placeholder="请选择是否中标" style="width: 100%" v-model="queryParam.isFin" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="订单状态" prop="orderStatus">
                <a-select placeholder="请选择订单状态" style="width: 100%" v-model="queryParam.orderStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderMarginStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="订单开始时间" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime" @change="startTimeChange" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="订单结束时间" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime" @change="endTimeChange"  format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="" tab="全部订单"/>
        <a-tab-pane v-for="pane in this.customDict.OrderMarginStatusEnum" :key="pane.type" :tab="pane.name">
        </a-tab-pane>
      </a-tabs>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="danger" :disabled="multiple" @click="handleRefund" v-hasPermi="['order:shop/margin:refund']">
          <a-icon type="edit" />退款
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:shop/margin:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        @change="handleSortChange"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="orderStatus" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.OrderMarginStatusEnum" :value="record.orderStatus"/>
        </span>
        <span slot="isFin" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isFin"/>
        </span>
        <span slot="payType" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.OrderPayTypeEnum" :value="record.payType"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['order:shop/margin:pay']" v-if="record.orderStatus===2110" />
          <a @click="$refs.createForm.handlePay(record)" v-if="record.orderStatus===2110"  v-hasPermi="['order:shop/margin:pay']">
            <a-icon type="edit" />确认支付
          </a>
          <a-divider type="vertical" v-if="record.orderStatus===2120" v-hasPermi="['order:shop/margin:refund']" />
          <a @click="handleRefund(record)" v-if="record.orderStatus===2120 && record.canRefund" v-hasPermi="['order:shop/margin:refund']">
            <a-icon type="edit" />退款
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageShopMargin,listShopMargin, refundShopMargin } from '@/api/order/shopMargin'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ShopMargin',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      activeKey: null,//被选中的tab
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: null,
        shopId: null,
        isFin:null,
        payType:null,
        mobile:null,
        shopName: null,
        payAmount: null,
        createBeginTime:null,
        createEndTime:null,
        payTime: null,
        isInvoice: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '订单编号',
          dataIndex: 'id',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          scopedSlots: { customRender: 'orderStatus' },
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '商铺名称',
          dataIndex: 'shopName',
          align: 'center'
        },
        {
          title: '保证金金额 ',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payAmount',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '是否中标',
          dataIndex: 'isFin',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'isFin' },
          align: 'center'
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          scopedSlots: { customRender: 'payType' },
          align: 'center'
        },
        {
          title: '微信订单号',
          dataIndex: 'outTradeNo',
          align: 'center'
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'payTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询订单-商铺保证金订单列表 */
    getList () {
      this.loading = true
     pageShopMargin(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.queryParam.orderStatus = index;
      this.getList();
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        shopId: undefined,
        shopSnapshot: undefined,
        payAmount: undefined,
        payTime: undefined,
        isInvoice: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    startTimeChange(date, dateString){
      this.queryParam.createBeginTime = dateString
    },
    endTimeChange(date, dateString){
      this.queryParam.createEndTime = dateString
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleRefund (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids= []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认退款?',
        content: '变更为已退款,如果是线上订单金额将原路退回',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return refundShopMargin(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '退款成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('order/shop-margin/export', {
            ...that.queryParam
          }, `订单-商铺保证金订单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
